import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../Button';
import logo from "./../../assets/logo.png";
import "./../../css/header.css";

const Header = () => (
  <header className="header sticky top-0 bg-white z-40">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 lg:px-8">
      <div className="flex items-center lg:text-2xl">
        <AnchorLink offset='100' className="flex flex-col justify-center items-center font-lilita" href="#home">
          <div className="font-lilita">
            LA FRABIQUE DU CODE
          </div>
          <div className="font-cavaet">
            artisant du web
          </div>
        </AnchorLink>
      </div>
      <div className="text-sm link-menu lg:w-1/2 flex lg:justify-around font-lilita">
        <AnchorLink className='lg:pr-0' style={{paddingRight: "4rem"}} href="#how">
          Notre expertise
        </AnchorLink>
        <AnchorLink className='lg:pr-0' style={{paddingRight: "4rem"}} href="#needs">
          Pour qui ?
        </AnchorLink>
        <AnchorLink href="#contact">
          Nous contacter
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <Button className="text-sm">06 25 98 22 25</Button>
      </div>
    </div>
  </header>
);

export default Header;
