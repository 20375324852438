import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const Seo = ({description, keywords, title, image, url, author }) => {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription = "La Fabrique du Code, votre expert en développement web. Des sites web sur mesure. Contactez-nous pour une expérience digitale unique." || data.site.siteMetadata.description;
                const metaTitle = "La Fabrique Du Code - artisant du web" || data.site.siteMetadata.title;
                const metaAuthor = "La Fabrique Du Code" || data.site.siteMetadata.author;
                const metaUrl = "https://lafabriqueducode.fr/" || process.env.GATSBY_PROXY || data.site.siteMetadata.url;
                const metaImage = image || process.env.GATSBY_PROXY + data.file.childImageSharp.fixed.src;
                const metaKeywords = keywords || [
                    "Création site web professionnel",
                    "Développeur web sur mesure",
                    "Agence web personnalisée",
                    "La Fabrique du Code site web",
                    "Conception site internet abordable",
                    "Portfolio en ligne artiste",
                    "Innovation digitale entreprise",
                    "Expertise React Gatsby",
                    "Site web pas cher",
                    "Création site responsive",
                    "Développeur Symfony PHP",
                    "La Fabrique du Code avis clients",
                    "Meilleure agence web",
                    "Sites web sur mesure",
                    "Expérience utilisateur site web"
                  ];
                return (
                    <Helmet
                        title={metaTitle}
                        meta={[
                            {
                                name: `description`,
                                content: metaDescription
                            },
                            {
                                property: `og:title`,
                                content: metaTitle
                            },
                            {
                                property: `og:description`,
                                content: metaDescription
                            },
                            {
                                property: `og:type`,
                                content: `website`
                            },
                            {
                                property: `og:image`,
                                content: metaImage
                            },
                            {
                                property: `og:local`,
                                content: `fr`
                            },
                            {
                                property: `og:url`,
                                content: metaUrl
                            },
                            {
                                name: `twitter:card`,
                                content: `summary`
                            },
                            {
                                name: `twitter:creator`,
                                content: metaAuthor
                            },
                            {
                                name: `twitter:title`,
                                content: metaTitle
                            },
                            {
                                name: `twitter:description`,
                                content: metaDescription
                            },                         
                          ].concat(
                              metaKeywords && metaKeywords.lenght > 0 ? {
                                  name: `keywords`,
                                  content: metaKeywords.join(`, `),
                              }
                              : []
                        )}
                        link={[
                            {
                                rel: `canonical`,
                                href: "https://lafabriqueducode.com/"
                            }
                        ]}
                    />
                );
            }}
        />
    );
};

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
                image
            }
        }
        file(relativePath: {eq: "icon.png"}) {
            childImageSharp {
              fixed(fit: CONTAIN, height: 315, width: 600, background: "#fff") {
                src
              }
            }
          }
    }
`;

export default Seo;